<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        :aria-expanded="String(isEventHandlerSidebarActive)"
        variant="primary"
        block
        @click="activateEditingSidebar()"
      >
        {{ $t('calendar.event.form.button.add') }}
      </b-button>
      <div class="mt-3">
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">
            {{ $t('calendar.calendars') }}
          </span>
        </h5>
        <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
          @change="refetchEvents"
        >
          {{ $t('calendar.select-all') }}
        </b-form-checkbox>
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="organizationUser in organizationUsers"
              :key="`organization-user-${organizationUser.id}`"
              name="event-filter"
              :value="organizationUser.calendar.id"
              class="mb-1"
              @change="refetchEvents"
            >
              {{ organizationUser.firstName }} {{ organizationUser.lastName }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: { },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true,
    },
    refetchEvents: {
      type: Function,
      require: true,
      default: () => {},
    },
  },
  setup(props) {
    const {
      organizationUsers,
      selectedCalendars,
      checkAll,
      activateEditingSidebar,
    } = useCalendarSidebar(props)

    return {
      organizationUsers,
      selectedCalendars,
      checkAll,
      activateEditingSidebar,
    }
  },
}
</script>

<style>

</style>
