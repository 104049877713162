<template>
  <div class="app-calendar overflow-hidden border">
    <div
      class="row no-gutters hide-unnecessary-hours"
      :class="{ 'calendar-sidebar-enabled': isCalendarOverlaySidebarEnabled }"
    >
      <!-- Sidebar -->
      <div
        v-if="isCalendarOverlaySidebarEnabled"
        class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        :class="{ 'show': isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isCalendarOverlaySidebarActive"
          :refetch-events="refetchEvents"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <rc-overlay
          :show="fetchingEvents"
          no-fade
          variant="white"
        >
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>  </rc-overlay>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{'show': isCalendarOverlaySidebarActive}"
        @click="isCalendarOverlaySidebarActive = false"
      />

      <calendar-event-sidebar @change="refetchEvents" />
    </div>
  </div>
</template>

<script>

import FullCalendar from '@fullcalendar/vue'
import useCalendar from './useCalendar'
import calendarEventFormValues from '@/views/_global/calendar-event-sidebar/calendarEventFormValues'
import CalendarEventSidebar from '@/views/_global/calendar-event-sidebar/CalendarEventSidebar'
import CalendarSidebar from '@/views/organization/calendar/calendar-sidebar/CalendarSidebar'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  components: {
    RcOverlay,
    FullCalendar, // make the <FullCalendar> tag available
    // CalendarSidebar,
    CalendarEventSidebar,
    CalendarSidebar,
  },
  setup() {
    const { formVisible } = calendarEventFormValues()
    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      validateCollisions,
      collisions,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      cancelEvent,
      fetchEvents,
      refetchEvents,
      fetchingEvents,
      calendarOptions,
      isCalendarOverlaySidebarEnabled,
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      validateCollisions,
      collisions,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      cancelEvent,
      refetchEvents,
      fetchingEvents,
      calendarOptions,
      isCalendarOverlaySidebarEnabled,
      isEventHandlerSidebarActive,

      // ----- UI ----- //
      formVisible,
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-context.scss';

.event-new {
  animation-iteration-count: 30;
  animation-duration: .5s;
  animation-name: outline-fadeout;
}

@keyframes outline-fadeout {
  to {
    -moz-box-shadow: inset 0 0 0 white;
    -webkit-box-shadow: inset 0 0 0 white;
    box-shadow: inset 0 0 0 white;
  }

  30% {
    -moz-box-shadow: inset 0 0 8px white;
    -webkit-box-shadow: inset 0 0 8px white;
    box-shadow: inset 0 0 8px white;
  }

  to {
    -moz-box-shadow: inset 0 0 0 white;
    -webkit-box-shadow: inset 0 0 0 white;
    box-shadow: inset 0 0 0 white;
  }
}
</style>
