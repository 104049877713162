/* eslint-disable no-underscore-dangle */
// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Full Calendar locale
import plLocale from '@fullcalendar/core/locales/pl'

// eslint-disable-next-line object-curly-newline
import { ref, computed, onMounted } from '@vue/composition-api'
import dayjs from 'dayjs'
import _ from 'lodash'
import store from '@/store'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { $themeBreakpoints } from '../../../../themeConfig'

import calendarEventFormValues from '@/views/_global/calendar-event-sidebar/calendarEventFormValues'
import isEventRecurringValidator from '@/views/_global/calendar-event-sidebar/functions/isEventRecurringValidator'
import role from '@/auth/role'
import CalendarEventTypeDictionary from '@/dictionary/CalendarEventTypeDictionary'

export default function userCalendar() {
  const { calendarEvent } = calendarEventFormValues()
  const refCalendar = ref(null)
  const fetchingEvents = ref(false)
  const me = computed(() => store.getters['auth/getUser'])
  const isCalendarOverlaySidebarEnabled = computed(
    () => me.value.roles.includes(role.advancedEducator) && store.getters['organization/organizationUsers'].length > 1,
  )
  const isCalendarOverlaySidebarActive = ref(null)
  const selectedCalendars = computed(() => store.getters['calendar/getSelectedCalendars'])

  store.commit('calendar/SET_SELECTED_CALENDARS', [me.value.calendar.id])

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  const prepareEventTitle = responseEvent => {
    const title = []

    if (responseEvent.service) {
      title.push(responseEvent.serviceObject.name)
    }

    const calendarEventCustomersLength = Object.keys(responseEvent.calendarEventCustomers).length
    if (responseEvent.participantLimit > 1) {
      title.push(`(${calendarEventCustomersLength}/${responseEvent.participantLimit})`)
    } else if (calendarEventCustomersLength === 1) {
      title.push(`${Object.values(responseEvent.calendarEventCustomerObjects)[0].customer.lastName} ${responseEvent.calendarEventCustomerObjects[0].customer.firstName}`)
    } else if (calendarEventCustomersLength.length > 1) {
      title.push(`${Object.values(responseEvent.calendarEventCustomerObjects)[0].customer.lastName} ${responseEvent.calendarEventCustomerObjects[0].customer.firstName} +${calendarEventCustomersLength - 1}`)
    }

    return title.join(', ')
  }

  const prepareEventObjectFromResponse = (responseEvent, eventId) => {
    const obj = {
      id: eventId || responseEvent.id,
      type: responseEvent.type,
      groupId: responseEvent.id,
      start: dayjs(responseEvent.startAt)
        .format(),
      end: dayjs(responseEvent.endAt)
        .format(),
      extendedProps: {
        isAdditionalTime: false,
        originalEvent: responseEvent,
      },
    }

    if (obj.type === CalendarEventTypeDictionary.Appointment) {
      obj.title = prepareEventTitle(responseEvent)
      obj.backgroundColor = responseEvent.serviceObject.color
      obj.borderColor = responseEvent.serviceObject.color
    }

    if (obj.type === CalendarEventTypeDictionary.TimeLock) {
      obj.className = 'time-lock-event'
      obj.editable = false
    }

    return obj
  }

  const prepareBackgroundEventObjectFromResponse = (responseEvent, eventId) => ({
    id: eventId || `${responseEvent.id}-background`,
    groupId: responseEvent.id,
    start: dayjs(responseEvent.startAt).subtract(responseEvent.additionalTimeBefore, 'minutes').format(),
    end: dayjs(responseEvent.endAt).add(responseEvent.additionalTimeAfter, 'minutes').format(),
    display: 'background',
    durationEditable: false,
    extendedProps: {
      isAdditionalTime: true,
    },
  })

  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  const prepareFetchedEvent = e => {
    const fetchedEvent = cloneNested(e)
    if (Object.keys(fetchedEvent.calendarEventCustomers).length > 0) {
      fetchedEvent.calendarEventCustomerObjects = Object.values(fetchedEvent.calendarEventCustomers)
      fetchedEvent.calendarEventCustomers = Object.values(fetchedEvent.calendarEventCustomers).map(calendarEventCustomer => calendarEventCustomer.customer.id)
    }

    if (fetchedEvent.service) {
      fetchedEvent.serviceObject = fetchedEvent.service
      fetchedEvent.service = fetchedEvent.service.id
    }

    return fetchedEvent
  }

  const formatFetchedEventsForFullCalendar = responseData => {
    const events = []

    responseData.forEach(responseEvent => {
      let clonedEvent = _.mergeWith(
        _.cloneDeep(store.state.calendarEventEditing.eventModel),
        responseEvent,
      )
      clonedEvent = prepareFetchedEvent(clonedEvent)

      if (clonedEvent.additionalTimeBefore || clonedEvent.additionalTimeAfter) {
        events.push(prepareBackgroundEventObjectFromResponse(clonedEvent))
      }

      events.push(prepareEventObjectFromResponse(clonedEvent))
    })

    return events
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        originalEvent,
      },
    } = eventApi

    originalEvent.startAt = dayjs(start).format()
    originalEvent.endAt = dayjs(end).format()
    originalEvent.title = title
    originalEvent.calendarEventSequence = originalEvent.calendarEventSequence || {}

    return originalEvent
  }

  // ------------------------------------------------
  // fetchOrganizationUsers
  // ------------------------------------------------
  const fetchOrganizationUsers = () => {
    store.dispatch('organization/getOrganizationUsers')
  }
  if (me.value.roles.includes(role.advancedEducator)) {
    fetchOrganizationUsers()
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEventTime = data => {
    const requestData = {
      startAt: dayjs(data.startAt).format(),
      endAt: dayjs(data.endAt).format(),
    }

    store.dispatch('calendar/patchCalendarEvent', { calendarEventId: data.id, data: requestData })
      .then(() => {
        refetchEvents()
      })
  }

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    fetchingEvents.value = true
    // Fetch Events from API endpoint
    Promise.all(
      selectedCalendars.value
        .map(
          calendarId => store.dispatch(
            'calendar/fetchCalendarEventsByCalendarId',
            {
              calendarId,
              from: dayjs(info.start)
                .format(),
              to: dayjs(info.end)
                .format(),
            },
          ),
        ),
    )
      .then(response => {
        successCallback(
          formatFetchedEventsForFullCalendar(
            response.map(res => res.data).flat(1),
          ),
        )
      })
      .catch(err => parseRequestError(err))
      .finally(() => {
        setTimeout(() => {
          fetchingEvents.value = false
        }, 300)
      })
  }

  let initialView = 'timeGridWeek'
  if (store.state.app.windowWidth < $themeBreakpoints.md) {
    initialView = 'timeGridDay'
  }
  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView,
    scrollTime: dayjs().subtract(2, 'hours').format('HH:MM:SS'),
    headerToolbar: {
      // start: 'sidebarToggle, prev,next, title',
      start: 'sidebarToggle, addEvent, prev,next, title',
      end: 'today,dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short',
    },
    nowIndicator: true,
    locale: plLocale,
    slotDuration: '00:15:00',
    allDaySlot: false,
    // height: '100%',
    contentHeight: 'auto',
    events: fetchEvents,
    eventDisplay: 'block',

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 5,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: localCalendarEvent }) {
      const { isAdditionalTime } = localCalendarEvent._def.extendedProps
      return [
        // Background Color
        'overflow-hidden',
        isAdditionalTime || dayjs().diff(localCalendarEvent._def.extendedProps.originalEvent?.createdAt, 'minute') > 5 ? '' : 'event-new',
      ]
    },

    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      // eslint-disable-next-line no-underscore-dangle
      if (clickedEvent._def.extendedProps.isAdditionalTime === false) {
        store.dispatch('calendarEventEditing/activateEditing', clickedEvent._def.publicId)
      }
    },

    eventContent(args) {
      let text = `<div><strong>${args.timeText}</strong></div>`
      const icon = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-cw"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>'
      const { originalEvent } = args.event._def.extendedProps

      if (args.view.type === 'dayGridMonth') {
        text = `${args.timeText}`
        if (originalEvent.service) {
          text += ` ${originalEvent.serviceObject.name}`
        }
      } else if (originalEvent) {
        text = `<div><strong>${args.timeText}</strong></div>`
        if (isEventRecurringValidator(originalEvent)) {
          text = `<div class="float-right mr-25">${icon} ${originalEvent.ordinalNumber}/${originalEvent.calendarEventSequence.calendarEventsCount}</div> ${text}`
        }

        if (originalEvent.service) {
          text += `<div>${originalEvent.serviceObject.name}</div>`
        }

        if (originalEvent.participantLimit > 1) {
          text += `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg> ${originalEvent.calendarEventCustomers.length}/${originalEvent.participantLimit}`
        } else if (originalEvent.calendarEventCustomers.length === 1) {
          text += `<div>${originalEvent.calendarEventCustomerObjects[0].customer.lastName} ${originalEvent.calendarEventCustomerObjects[0].customer.firstName}</div>`
        } else if (originalEvent.calendarEventCustomers.length > 1) {
          text += `<div>${originalEvent.calendarEventCustomerObjects[0].customer.lastName} ${originalEvent.calendarEventCustomerObjects[0].customer.firstName} +${originalEvent.calendarEventCustomers.length - 1} <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg></div>`
        }
      }
      return {
        html: text,
      }
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
      addEvent: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'add',
        click() {
          store.dispatch('calendarEventEditing/activateEditing')
        },
      },
    },

    dateClick(info) {
      calendarEvent.value.startAt = info.dateStr
      store.dispatch('calendarEventEditing/activateEditing')
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEventTime(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEventTime(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    refCalendar,
    calendarOptions,
    isCalendarOverlaySidebarEnabled,
    isCalendarOverlaySidebarActive,
    fetchingEvents,

    refetchEvents,
    fetchEvents,
  }
}
